<template>
  <div class="login-container">
    <form @submit.prevent="login" class="login-form">
      <h2>Login Required</h2>
      <div class="form-group">
        <input 
          type="password" 
          v-model="password"
          class="form-control"
          required
        >
      </div>
      <button type="submit" class="btn btn-primary">Login</button>
      <p v-if="error" class="error-message">{{ error }}</p>
    </form>
  </div>
</template>

<script>
export default {
  name: 'LoginView',
  data() {
    return {
      password: '',
      error: ''
    }
  },
  methods: {
    login() {
      // Replace this with your desired password
      const correctPassword = 'famcAw-1tawvu-bybmor';
      if (this.password === correctPassword) {
        localStorage.setItem('isAuthenticated', 'true');
        this.$emit('login-success');
        this.$router.push(this.$route.query.redirect || '/');
      } else {
        this.error = 'Incorrect password';
      }
    }
  }
}
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form {
  width: 300px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: white;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.form-group {
  margin-bottom: 15px;
}
</style> 